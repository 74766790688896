import revive_payload_client_vj7iVBKLdE from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_0WJj6aTrMn from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_QggJDxljC1 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_E14aFFJGtI from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_tvlyCmlkfR from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8awtCMD6G6 from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_63veAS33rF from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YClEr7KIbj from "/opt/buildhome/repo/node_modules/.pnpm/@pinia+nuxt@0.5.2_rollup@4.20.0_vue@3.4.35/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/opt/buildhome/repo/.nuxt/components.plugin.mjs";
import prefetch_client_MpMZ5oSE2k from "/opt/buildhome/repo/node_modules/.pnpm/nuxt@3.12.4_rollup@4.20.0_sass@1.77.8_vite@5.3.5/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_NKQ3DIIoMW from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.mjs";
import i18n_jT8HISAb11 from "/opt/buildhome/repo/node_modules/.pnpm/@nuxtjs+i18n@8.3.3_rollup@4.20.0_vue@3.4.35/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
export default [
  revive_payload_client_vj7iVBKLdE,
  unhead_0WJj6aTrMn,
  router_QggJDxljC1,
  payload_client_E14aFFJGtI,
  navigation_repaint_client_tvlyCmlkfR,
  check_outdated_build_client_8awtCMD6G6,
  chunk_reload_client_63veAS33rF,
  plugin_vue3_YClEr7KIbj,
  components_plugin_KR1HBZs4kY,
  prefetch_client_MpMZ5oSE2k,
  switch_locale_path_ssr_NKQ3DIIoMW,
  i18n_jT8HISAb11
]